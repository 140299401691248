.bureau{
    text-align: center;
    background-color: var(--dark-background);
    backdrop-filter: blur(5px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    padding-bottom: 300px;

    //meet the team 
    

    //style for member next to each other
    .double{
        display: flex;
        justify-content: space-around;
        flex-wrap:wrap;
    }
}