@import '../../../variables';
.welcome{
    min-height:100vh;
    background-color: var(--less-dark-background);
    display: flex; 
    align-items: center;

    //the disover polygon
    .green-part{
        background: linear-gradient(var(--primary-green), var(--secondary-green));
        color: $primary-white;
        padding: 70px 0;
        max-width: 100%;
        padding-left: 30px;
        padding-right: 180px;
        clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
        transform: scaleX(0);
        transform-origin: left; 
        animation: back-to-normal 700ms ease forwards;

        // the title : Discover
        h1{
            font-weight: 700;
            font-size: var(--big-font);
            margin-bottom:20px;
        }

        // the paragraphs
        p{
            font-size: var(--big-paragraph);
        }

        // the last paragraph should be a little bit bigger
        p:last-of-type{
            margin-top: 30px;
            font-size: var(--bigger-paragraph);
        }
    }
}


// removing padding on smaller phones
@media(max-width: 500px) {
    .welcome .green-part{
        padding:40px 10px;
        padding-right: 50px;
    }
}