.bureau-member{
    margin: 100px 0 ;
    max-width: 100%;
}

//the content of each member
.bureau-member .content {
    position:absolute;
    z-index: 4;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 300ms ease;

    //disappearing content
    &>*{
        transform : translateY(50px);
        opacity: 0;
        transition: transform 300ms ease 250ms,
            opacity 300ms ease 250ms;
    }

    //icons
    .links{
        display:flex;
    }
    a{
        color: white;
        margin-left: 5px;
        transition: color 300ms ease;
        svg{
            font-size: 1.2rem;
        }
        &:hover{
            color: var(--primary-green);
        }
    }

    //the h2
    h2{
        position: relative;
        font-size: var(--little-big-font)
    }

    //green line
    h2::after{
        content:'';
        position: absolute; top: 90% ; left: 0 ;
        width: 70%;height: 3px;
        background-color: var(--primary-green);
        transform: scaleX(0);
        transform-origin : left ;
        transition: transform 1000ms ease ;
    }
    
    //showing content on hover
    &:hover{
        opacity: 1;
        h2::after{
            transform: none;
        }
    }
    &:hover > *{
        opacity :1;
        transform: none;
    }
}


//setting image style
.bureau-member .image{
    
    width: 300px ;
    height: 300px;
    position: relative;
    max-width: 100%;
    border-radius: 15px;
    overflow:hidden;
    flex-shrink:0;
    display: flex; justify-content: center; align-items: center;
    //spinning wheel
    svg {
        font-size: 5rem;
        position: relative;
        transition: transform 2000ms ease;
    }
}

//image container
.bureau-member .img{
    overflow: hidden;
    position: relative;
    position: absolute;
    width: 100%; 
    height: 100%;
    background-position: center center;
    clip-path : inset(0 95% 0 0);
    transition: clip-path 2000ms ease;

    //blur layer on appear
    &::after{
        content: '';
        position: absolute; 
        top:0; left: 0; 
        width: 100%; height: 100%;
        z-index: 3;
        background-color: var(--dark-background);
        backdrop-filter: blur(14px);
        transition: opacity 5000ms ease 200ms;
    }

    //te actual image
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0; left:0;
        transform : scale(3);
        object-fit: cover;
        z-index :1;
        transition: transform 2200ms ease;
    }
}

//changes when visible on screen
.bureau-member.visible{
    .img{
        clip-path: inset(0 0 0 0);
        img {
            transform: none;
        }
        &::after{
            opacity : 0;
        }
    }
    .image svg{
        transform: rotate(360deg)
    }
}