.know{
    text-align: center;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    z-index: 1;
    color: black;
    padding: 100px 0 ;
    padding-top: 70px;

    //layer above background
    &::after{
        content: '';
        position: absolute;
        top:0; left: 0; width: 100%; height: 100%;
        background-color: rgba(255, 255, 255, 0.89);
        z-index: -1;
    }

    //content
    h1{
        font-weight: 500;
        margin-bottom: 10px;
    }
    p{
        text-align: left;
    }
}