.fixed-backg{ 
    position: fixed;
    z-index: -1;
    top:0;
    left:0; 
    width: 100%;
    height: 100vh;
    background-size: cover;

    //setting gear to absolute position
    .gear{
        position: absolute;
    }

    //odd gears should turn to right
    .gear:nth-of-type(odd){
        animation: rotate-360 5s linear infinite; 
    }

    //even to the left
    .gear:nth-of-type(even){
        animation: rotate--360 5s linear infinite;
    }
}

//spin animations
@keyframes rotate-360{
    100%{
        transform: rotate(360deg);
    }
}
@keyframes rotate--360{
    100%{
        transform: rotate(-360deg);
    }
}