.image{
    width: 300px ;
    height: 300px;
    position: relative;
    max-width: 100%;
    flex-shrink:0;
    display: flex; justify-content: center; align-items: center;
    svg {
        font-size: 5rem;
        position: relative;
        transition: transform 2000ms ease;
    }

    //img container
    .img{
        overflow: hidden;
        position: absolute;
        width: 100%; 
        height: 100%;
        background-position: center center;
        clip-path : inset(0 95% 0 0);
        transition: clip-path 2000ms ease;

        //blur when appearing
        &::after{
            content: '';
            position: absolute; 
            top:0; left: 0; 
            width: 100%; height: 100%;
            z-index: 3;
            background-color: var(--dark-background);
            backdrop-filter: blur(14px);
            transition: opacity 5000ms ease 200ms;
        }
        
        // the image
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0; left:0;
            transform : scale(3);
            object-fit: cover;
            z-index :1;
            transition: transform 2200ms ease;
        }
    }
}

.image.visible {
    .img{
        clip-path: inset(0 0 0 0);
        img {
            transform: none;
        }
        &::after{
            opacity : 0;
        }
    }
    svg{
        transform: rotate(360deg)
    }
}