#bootcamp {
    color: white;

    .description {
        background-color: white;
        color:black;
        display: flex;
        padding: 50px;
        align-items:center;
        justify-content: center;
        gap: 100px;
        video {
            width: 600px;
            max-width: 95%;
        }
        p{
            font-size: 1.5rem;
        }
    }

    .gallery-container{
        background-color:var(--dark-background)
    }

    .gallery-image {
        width: 300px;
        height: 200px; 
        object-fit: cover; 
        margin: 0 10px;
    }

    .presentation-title{
        width: 90%;
        margin :auto;
    }

}

@media(max-width: 1000px) {
    #bootcamp {
        .description{
            flex-direction: column;
        }
    }
}