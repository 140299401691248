.linking{
    min-height: 600px;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 1; 
    display: flex; 
    flex-direction: column;
    justify-content: center;
    text-align:center;
    padding: 50px 0;
    overflow-x: hidden;

    // layer above background
    &::after{
        content: '';
        position: absolute;
        z-index: -1; 
        width: 100%; height: 100%;
        top: 0; left: 0 ; 
        background-color: rgba(255, 255, 255, 0.89);
    }

    //main titles
    &>h2, &>p{
        margin-bottom: 20px;
    }

    //oraganizing cards
    .card-container{
        display: flex; 
        justify-content: space-around;
        align-items: stretch;
        flex-wrap: wrap;
    }

    //card style
    .card{
        opacity: 0;
        transition: opacity 2000ms ease ,
                transform 2000ms ease ;
    }
    .card:nth-of-type(odd){
        transform: translateX(20%)
    }
    .card:nth-of-type(even){
        transform: translateX(-20%)
    }
    .card.visible{
        transform: none; opacity: 1;
    }
    .card{
        width: 320px; 
        max-width: 100%;
        margin:20px;
        background-color: white;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center; 
        border-radius: 33px;
        box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.25);
        -webkit-box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.25);
        overflow:hidden;

        //the image container
        .image-contain{
            position: relative;
            z-index: 1; 

            // the green circle
            &::after {
                content: "";
                position: absolute;
                width: 100px;height: 100px;
                top:50%; left:50%;
                background-color: #13bf0079;
                z-index:-1;
                border-radius: 50%;
                transform:translate(-50%,-57%) scale(0.8);
                transition: transform 700ms ease;
            }
        }

        //card title and paragraph
        h3,p{
            position: relative;
            z-index: 2;
            opacity: 0 ; 
            transform : translateY(50px);
            transition: opacity 500ms ease ,
                transform 500ms ease ;
        }

        //the image
        img{
            width: 120px;
            position: relative;
        }

        //settng space between image and paragrpahs
        .image-contain,h3{
            margin-bottom: 20px
        }
    }


    //card hover effect 
    .card:hover{

        //green circle get bigger
        .image-contain::after{
            transform: translate(-50%,-50%) scale(7);
        }

        //content appears
        h3,p{
            opacity: 1; 
            transform : none;
        }
    }
}