//return any object to normal visible
@keyframes back-to-normal {
    100% {
        transform: none; 
        opacity: 1; 
    }
}

@keyframes clip-path-to-normal {
    100%{
        clip-path: inset(0 0 0 0);
    }
}

@keyframes disappear-left {
    100% {  
        opacity : 0 ;
        transform : translateX(-50px);
    }
}