$image-size: 300px;

.tesla{
    overflow-x: hidden;
    .container{
        display:flex;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh ;
        &>div{
            display: flex;
        }
    }

    // the tesla image
    img{
        max-width: 100%;
        width: $image-size; 
        height: $image-size;
        order :1; 
        margin-left: 30px;
        flex-shrink: 0;
        transform: translateX(100px);
        opacity: 0;
        transition: transform 2000ms ease,
            opacity 2000ms ease;
    }

    // the quote and author
    .content{
        padding: 30px;
        background: rgba(0, 0, 0, 0.603);
        flex: 1;
        overflow: hidden;
        align-self: center;
        clip-path: inset(0 0 100% 0);
        transition: clip-path 1000ms ease;

        // the author
        .tesla-name{
            margin-top: 30px;
        }

        //the quote
        .tesla-quote{
            transform: translateX(-100px);
            opacity: 0;
            transition: transform 1000ms ease 500ms,
                opacity 1000ms ease 500ms;
        }
    }
}

//changes when visible on screen
.tesla.visible {
    img{
        transform: none;
        opacity: 1;
    }
    .content {
        clip-path: inset(0 0 0 0);
        .tesla-quote{
            transform :none;
            opacity: 1;
        }
    }
}


//change flex on mobile
@media (max-width: 700px) {
    .tesla{
        .container>div{
            flex-direction: column;
            align-items: center;
        }
        img{
            order: 0;
            margin-bottom: 30px;
        }
    }
}