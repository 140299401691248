.activities {
    color:white;
    
    
    .navbar{
        min-height: 200px; 
        position: sticky;
        top:75px;left:0;
        z-index: 1;
        background-size: cover;
        background-attachment: fixed ;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        &::after{
            content: ''; 
            position: absolute; top:0; left:0;
            width: 100% ; height: 100%;
            background-color: rgba(255, 255, 255, 0.92);
            z-index: -1;
        }
        button{
            color: var(--primary-green);
            border: 3px solid var(--primary-green); 
            background-color: transparent;
            border-radius: 500px;
            padding: 10px 25px;
            font-size:var(--little-big-font);
            transition: background-color 300ms ease;
            cursor: pointer;
            margin: 20px;
            &:hover , &.current{
                background-color: var(--primary-green);
                color: white;
            }
        }
    }

    .activities-container{
        min-height: 500px;
        background-color: var(--primary-green);
    }

    .activities-container{
        padding: 100px 0;
    }
    .activity{
        display:flex;
        margin-bottom: 100px;
        .content{
            padding: 30px;
            h2{
                margin-bottom: 30px;
            }
        }
        align-items: center;
    }
    .activity:nth-of-type(even){
        .activity-image{
            order: 1;
        }
    }
    .gallery-container{
        background-color:var(--dark-background)
    }

    .gallery-image {
        width: 300px;
        height: 200px; 
        object-fit: cover; 
        margin: 0 10px;
    }

    .presentation-title{
        width: 90%;
        margin :auto;
    }
    
    .gallery-card{
        width: 300px;
        height: 420px;
        margin: 0 10px;
        background-color: white; 
        color: black;
        overflow: hidden ; 
        border-radius: 20px;
        transition: transform 300ms ease;
        cursor: pointer; 

        .gallery-card-img{
            width: 100%;
            height: 170px;
            object-fit: cover;
        }
        .content{
            height: 230px;
            overflow-y: auto;
            padding: 15px 20px;
            &::-webkit-scrollbar {
                width: 5px ;
            }
        }
        &:hover{
            transform: scale(1.02);
        }
    }

    


}

@media (max-width: 950px){
    .activities{
        .activity{
            flex-direction: column;
            align-items: center;
            .content{
                text-align: center;
            }
        }
        .activity:nth-of-type(even){
            .activity-image{
                order: 0
            }
        }
        .navbar{
            position: relative; top: 0; left: 0;
        }
    }
}