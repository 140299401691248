.event-show-image{
    position: fixed; 
    top:0; left: 0;
    width: 100%; 
    height: 50vh;
    background-color: white;
    z-index: 200;
    object-fit: contain;
    background-color: var(--primary-green);
    transform: translateX(-100%);
    transition: transform 1000ms ease;
    &.shown-event{
        transform: none;
    }

    opacity: 1 !important;
    animation: none !important;
    
}
.event-show-content{
    position: fixed; 
    bottom:0; left:0;
    width: 100%; 
    height: 50vh;
    background-color: white;
    z-index: 200;
    object-fit: cover;
    padding: 50px;
    overflow-y: auto;
    
    transform: translateX(100%);
    transition: transform 1000ms ease;
    &.shown-event{
        transform: none;
    }
    opacity: 1 !important;
    animation: none !important;
}

.event-show-close-button {
    background-color: var(--primary-green);
    width: 50px !important;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;

    border: 3px solid #ccc;
    color: white;

    position: fixed;
    z-index: 201;
    top: 50%;
    right: 100px;
    transform: translateY(-50%) translateX(300%) rotate(360deg);
    transition: transform 1000ms ease;
    &.shown-event {
        transform: translateY(-50%);
        &:hover{
            transition: transform 300ms ease;
            transform: translateY(-50%) scale(1.1) rotate(90deg);
        }
    }
    
}