.numbers{
    background-size: cover;
    background-attachment: fixed;
    
    min-height: 450px;
    padding: 20px 0;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: white;

    // title
    h3{
        margin-bottom:15px;
    }

    //number
    p{
        font-size: var(--bigger-paragraph);
    }

    .number-card:nth-of-type(even){
        transform: translate(50px,50px);
    }
    .number-card:nth-of-type(odd){
        transform: translate(-50px,-50px);
    }
    .number-card{
        text-align: center;
        opacity: 0;
        transition: transform 3000ms ease,
            opacity 3000ms ease;
        &.visible {
            opacity: 1;
            transform: none;
        }
    }
}