nav{
    position: fixed; 
    top:0; left: 0;
    width: 100%;
    z-index: 100;
    transition:background-color 500ms ease;
    .container{
        max-width: 1300px;
        padding: 15px;
        display:flex;
        justify-content: space-between;
        align-items:center;
        transition: padding 500ms ease;
    }

    //fixing logo height
    .logos{
        height: 65px;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        img{
            height: 100%;
            transition: transform 500ms ease;
        }
    }

    //navbar buttons
    ul{
        display:flex;
        a{
            margin-left: 25px;
            font-family: var(--nav-font);
            font-size: var(--little-big-font) ;
            color: white;
            text-decoration: none;
            position: relative;
            &::after{
                content :'';
                position: absolute;
                top:100%; left: 0 ; 
                width: 100%; height: 3px;
                background-color: var(--primary-white);
                transform : scaleX(0);
                transform-origin: right; 
                transition: transform 500ms ease;
            }
            &:hover::after{
                transform: none;
                transform-origin: left;
            }
            &.active::after{
                transform: none;
            }
        }
    }

    //the show nav button on mobile
    .nav-button{
        display: none;
        cursor: pointer;
    }
    .line{ 
        height: 3px;
        background-color: white;
        transition: transform 300ms ease;
        border-radius: 5px;
    }
    .line:first-of-type, .line:last-of-type{
        transform-origin: right;
    }

    // the show nav after clicked
    .nav-button.clicked{
        .line:first-of-type{
            transform: translateY(0.5px) rotate(-45deg);
            
        }
        .line:nth-of-type(2){
            opacity: 0;
        }
        .line:last-of-type{
            transform: translateY(-0.5px) rotate(45deg);
        }
    }
}

//the navbar changes after scroll
nav.scrolled{
    background-color: var(--primary-transparent-green);
    backdrop-filter: blur(10px);
    box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.35);
    -webkit-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.35);
    -moz-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.35);
    .container{
        padding: 0;
    }
    .logos{
        img{
            transform: translateY(-100%);
        }
    }
}


//restying navbar on mobile
@media(max-width: 900px){
    nav{
        //showing the show nav button
        .nav-button{
            $size: 22px;
            display: flex;
            flex-direction: column;
            width: 25px; height: $size;
            justify-content: space-between;
        }

        //restyling the ul buttons
        ul{
            position: absolute;
            height: 100vh; width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            top:0; left: 0;
            background-color: var(--primary-transparent-green);
            backdrop-filter: blur(10px);
            transform: scale(0);
            transform-origin : top right;
            transition: transform 1500ms ease,
                border-radius 1500ms ease 500ms;
            border-radius: 0 0 0 100%;
            overflow: hidden;
            a{
                transform: translateX(-50px);
                opacity:0;
                color: white;
                &::after {
                    background-color: white;
                }
            }
        }
    }

    //showing the ul when clicking on show nav button
    nav.clicked{
        ul{
            transform: none;
            border-radius: 0 0 0 80px;
            a{
                animation: back-to-normal 500ms ease 750ms forwards;
            }
        }
    }
}

@media (max-width: 300px){
    form{
        display: none;
        .input-container{
            margin-left: 0 !important;
            input {
                max-width: 90%;
            }
        }
    }
}