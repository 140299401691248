#latest-posts {
    background-color: white;
    padding: 30px 0;
    text-align: center;
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .flex-container {
        width:100%;
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        img.non-fixed {
            margin: 30px;
            width: 400px; height: 400px;
            object-fit: contain;
            transition: background-color 300ms ease,
                transform 300ms ease;
            cursor: pointer;
            max-width: 90%;
            &:hover{
                background-color: var(--primary-green);
                transform: scale(1.05);
            }
        }
        .fixed {
            position: fixed;
            top:0; left: 0;
            width: 100%;
            height:80vh;
            z-index: 100;
            top: 50%;
            transform: translateY(-50%) scaleY(0);
            -webkit-transform: translate3d(0, -50%, 0) scaleY(0);
            -o-transform: translate3d(0, -50%, 0) scaleY(0);
            background-color: var(--primary-green);
            transition: transform 700ms ease;
            &.shown{
                transform: translateY(-50%);
                img{
                    opacity:1;
                }
            }
            img{
                position: absolute;
                top:0;left: 0;
                width: 100%;
                height:100%;
                object-fit: contain;
                opacity: 0;
                transition: opacity 300ms ease 300ms;
            }
            button {
                position: absolute; 
                right:5px ;
                top: 5px;
                background-color:transparent;
                border: none;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
                svg{
                    font-size: 30px;
                    color: white;
                }
            }
            
        }
    }
}

@media (max-width:770px) {
    #latest-posts{
        .flex-container .fixed{
            height: 70vh;
        }
    }
}