.team{
    min-height: 100vh;
    background-color: #051400c2;
    backdrop-filter: blur(5px);
    color: white ;
    position : relative;
    z-index: 1; 
    padding-bottom: 300px;

    // the entire member
    .member {
        min-height: 60vh  ;
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow-x: hidden;
        .container {
            display: flex; 
         .image{
                order: 0;  
            }
        }

        // the name and quote
        .content{
            padding: 20px 40px;
            h2{
                padding-bottom: 20px;
            }
            p{
                transform: translateX(300px);
                opacity: 0 ;
                transition : transform 1000ms ease , 
                    opacity 1000ms ease;
            }
        }
    }

    //changes for even members
    .member.inverse{
        .container .image {
            order :1;
        }
        .content p {
            transform: translateX(-300px);
            opacity: 0;
        }
    }


    .member .content.visible p {
        transform : none; 
        opacity: 1 ;
    }
}


// flex to column on mobile
@media (max-width: 700px) {
    .team{
        .member{
            .container{
                flex-direction: column;
                align-items: center;
            }
        }

        //removing inverse image for even members
        .member.inverse{
            .container .image{
                order : 0 ;
            }
        }
    }
}