#text-line-reveal{
    overflow: hidden;
    &>div{
        transform: translateY(100%);
        transition: transform 1000ms ease;
    }

    &.visible> div {
        transform: none;
    }
}