.contact {
    color: white;
    background-image:  linear-gradient(180deg,var(--primary-green), var(--secondary-green));
    position: relative;
    z-index:2;

    .logo{
        height: 150px;
    }

    //wave above
    .behind-wave{
        position: absolute;
        bottom: 96%;
        left: 0;
        width: 100%;
        svg {
            path{
                fill: var(--primary-green)
            }
        }
    }

    //flexing the items
    .container{
        padding-top:10px ;
        padding-bottom: 100px;
        display :flex;
        justify-content: space-between;
        &>div{
            flex: 1;
        }
    }

    //left and right style
    .cos-left, .cos-right{
        text-align: center;
        img{
            margin-bottom: 20px;
        }
    }

    //right style
    .cos-right {
        text-align: left;
        margin-left: 80px;
        flex: 0.9 !important;
        display: flex; 
        flex-direction: column;
        justify-content: center;

        // fb and linkedin icons
        .icons{
            margin-top: 20px;
        }
        a{
            color: white;
            margin-left: 30px;
            font-size: 1.5rem;
            transition: color 300ms ease;
            &:hover{
                color:green;
            }
        }
    }
}

//mobile changes
@media (max-width: 700px){
    .contact {
        .container{
            flex-direction: column;
            align-items: center;
        }
        .cos-left{
            margin-bottom: 30px;
        }
        .cos-right{
            margin-left: 0;
        }
    }
}