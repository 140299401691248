.hor-gallery {
    display: flex; 
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0;
    &>* {
        flex-shrink: 0 ;
    }
    &::-webkit-scrollbar {
        height: 8px ;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--primary-green);
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--secondary-green);
    }
}
