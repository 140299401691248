.contact-us{
    min-height: 100vh;
    background-color: var(--dark-background);
    display: flex; 
    flex-direction: column;
    justify-content: center;
    color: white;
    
    .horizontal-bar {
        min-height: 500px; 
        background-image:linear-gradient(var(--primary-green), var(--secondary-green));
        display: flex;
        clip-path: inset(100% 0 0 0);
        animation: clip-path-to-normal 1000ms ease forwards;
        &>*{
            flex-grow: 1;
            flex-basis: 0;
            flex-shrink: 1;
        }
    }

    .map{
        border: 0 ;
        clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
        min-height: 300px;
    }

    form{
        padding: 30px;
        display: flex;
        flex-direction: column;
        .Description {
            flex: 1;
            margin: 20px 0 ;
            .input-container{
                flex: 1;
                textarea{
                    width: 100%;
                    height: 100%;
                    resize: none;
                    min-height: 100px;
                }
            }
        }
    }

    .form-information{
        $space: 20px;
        display: flex;
        &>*{
            flex:1;
        }
        &>*:first-of-type{
            margin-right: $space/2;
        }
        &>*:last-of-type{
            margin-left: $space/2;
        }
    }

    .input-container{
        position: relative;
        margin: 20px 0;
        margin-left: 15px;
        input:focus + .line ,textarea:focus + .line{
            transform: none;
        }
        .line{
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            top:100%; left:0;
            transform: scaleX(0.2);
            transform-origin: left;
            background-color: white;
            transition: transform 500ms ease;
        }
    }

    label{
        font-size: 1.3rem;
    }

    input, textarea {
        background-color: transparent;
        color: white;
        border: none;
        outline: none;
        position: relative;
        font-size: 1.2rem;
        transform-origin: top left;
        transition: transform 300ms ease;
        padding: 5px;
        &:focus{
            transform: scale(0.9);
        }
    }

    button {
        align-self: flex-start;
        padding: 10px 30px;
        font-size: 1.2rem;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        transition: background-color 300ms ease;
        cursor: pointer;
        &:hover{
            color: green;
            background-color: white;
        }
    }
}

//placeholder
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d6d6d6;
    opacity: 1; // firefox
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:  #d6d6d6;
  }
  
::-ms-input-placeholder { /* Microsoft Edge */
    color:  #d6d6d6;
}

@media  (max-width: 1100px) {
    .contact-us{
        .form-information{
            flex-direction: column;
        }
    }
}

@media  (max-width: 700px) {
    .contact-us{
        .horizontal-bar{
            display:block;
            margin: 200px 0;
        }
        .form-information{
            &>*{
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
        .map{
            clip-path: none;
            width: 100%;
        }
    }
}

